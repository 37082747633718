:root {
  --toastify-color-progress-dark: var(--color-border);
  --toastify-icon-color-info: var(--color-primary);
  --toastify-color-progress-info: var(--color-primary);
  --toastify-icon-color-success: var(--color-secondary);
  --toastify-color-progress-success: var(--color-secondary);
  --toastify-color-progress-warning: #ee8a46;
  --toastify-icon-color-warning: #ee8a46;
  --toastify-color-progress-error: #e55656;
  --toastify-icon-color-error: #e55656;
  --toastify-font-family: "Plus Jakarta Sans";
  --toastify-text-color-light: var(--color-header);
}

// Transition Cubic Bezier Default
@mixin t1($time: 0.3s, $el: all, $ease: cubic-bezier(0.17, 0.67, 0.83, 0.67)) {
  transition: $el $time $ease;
}
.t1 {
  @include t1;
}
.border-color {
  border-color: var(--color-border);
}

// Font smoothing
@mixin fontSmooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Full width pop out of container
.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

// Spinner helper class
#loading-spinner {
  animation: loading-spinner 1s linear infinite;
}

@keyframes loading-spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

[data-id="tooltip"] {
  padding: 4px 7px !important;
  font-size: 11px !important;
  border-radius: 8px !important;
  background-color: var(--color-tooltip) !important;
  &:after {
    opacity: 0;
  }
}

select {
  color: var(--color-text);
}
