:root,
.light {
  --color-header: #1d335f;
  --color-paragraph: #61708f;
  --color-paragraph-shade: #8f9cb6;
  --color-border: #dde2e7;
  --color-border-hover: #e8ecef;

  --color-bg-900: #f4f6f8;
  --color-bg-800: #f6f8fb;
  --color-bg-700: #f8fafc;
  --color-bg-600: #fbfbfc;

  --color-dark: #050e20;

  --color-primary-dark: #0a94b2;
  --color-primary: #0f9fbf;
  --color-primary-light: #12afd2;

  --color-secondary-dark: #2fd3a3;
  --color-secondary: #34e0ad;
  --color-secondary-light: #34edb6;

  --color-tooltip: #081736;

  .dark {
    --color-header: #ffffff;
    --color-paragraph: #b7bcc5;
    --color-paragraph-shade: #878f9f;
    --color-border: #0e2046;
    --color-border-hover: #122650;

    --color-bg-900: #081736;
    --color-bg-800: #09193a;
    --color-bg-700: #0b1b3d;
    --color-bg-600: #0c1d41;
  }
}
