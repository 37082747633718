@tailwind base;
@tailwind components;
@tailwind utilities;

// #w3a-modal .w3a-modal__content {
//   background-color: var(--bg1);
// }

// #w3a-modal .w3a-text-field {
//   background-color: var(--bg1);
// }

@import "areas/colors";
@import "areas/utility";
@import "areas/body";
@import "areas/buttons";
@import "areas/typography";
@import "areas/forms";
@import "sections/_glob";
