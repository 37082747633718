input,
textarea,
option,
select {
  // @apply mb-3;
  border-color: var(--color-border) !important;
  border: 0;
}

.t-input {
  @apply block w-full rounded shadow p-2 px-4 bg-white outline-0 ring-1 ring-transparent transition-all;
  &:focus {
    @apply ring ring-gray-200 ring-opacity-25 border-primary #{!important};
  }
}

.t-select {
  @extend .t-input;
  @apply px-2 pr-6;
}

.t-select-dark {
}
