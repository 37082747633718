.stakeTable {
  tr td:first-child {
    @apply rounded-l;
  }
  tr td:last-child {
    @apply rounded-r;
  }

  // tr:last-child td:first-child {
  //   border-bottom-left-radius: 10px;
  // }
  // tr:last-child td:last-child {
  //   border-bottom-right-radius: 10px;
  // }

  tr:first-child td {
    border-top-style: solid;
  }
  tr td:first-child {
    border-left-style: solid;
  }
}
