body {
  // @apply bg-bg-800;
  @apply bg-bg-900 text-base;
}

.container {
  max-width: 800px;
  // @apply px-4 md:px-5;

  &-lg {
    // @apply mx-auto px-5;
    max-width: 1140px;
  }
  &-sm {
    // @apply mx-auto px-5;
    max-width: 750px;
  }
}
