@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap");

:root {
  --header-line-height: 100%;
  --header-letter-spacing: 0;
  --header-font-weight: 600;
  --header-margin: 0 0 0px 0;

  --paragraph-line-height: 120%;
  --paragraph-letter-spacing: 0.1px;
  --paragraph-font-weight: 500;
  --paragraph-bold-weight: 700;
  --paragraph-margin: 0 0 0px 0;

  --text-3xl: 28px;
  --text-2xl: 24px;
  --text-xl: 22px;
  --text-lg: 20px;
  --text-md: 18px;
  --text-base: 16px;
  --text-sm: 14px;
  --text-xs: 12px;
  --text-2xs: 11px;
}

@screen md {
  :root {
    --text-3xl: 32px;
    --text-2xl: 28px;
    --text-xl: 24px;
    --text-lg: 22px;
    --text-md: 18px;
    --text-base: 15px;
    --text-sm: 14px;
    --text-xs: 12px;
    --text-2xs: 11px;
  }
}

// Font styling
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: var(--header-margin);
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: var(--header-line-height);
  letter-spacing: var(--header-letter-spacing);
  font-weight: var(--header-font-weight);
  @apply text-header font-brand;
}

body {
  @apply text-header font-brand;
  font-size: var(--text-base);
}

p,
small,
li,
b,
ul {
  margin: var(--paragraph-margin);
}

p,
small,
li,
b,
ul,
span {
  @apply text-base font-sans text-paragraph;
  @include fontSmooth;
  line-height: var(--paragraph-line-height);
  letter-spacing: var(--paragraph-letter-spacing);
  font-weight: var(--paragraph-font-weight);
  font-size: var(--text-base);
}

h1,
.h1 {
  @apply text-2xl;
}
h2,
.h2 {
  @apply text-xl;
}
h3,
.h3 {
  @apply text-lg;
}
h4,
.h4 {
  @apply text-md;
}
p {
  @apply text-base;
  // @apply mb-4;
}
small,
.small {
  @apply text-xs;
}
b,
strong {
  font-weight: var(--paragraph-bold-weight);
}

// Button typography settings
.btn {
  line-height: var(--btn-line-height);
  letter-spacing: var(--btn-letter-spacing);
  font-weight: var(--btn-font-weight);
  margin: var(--btn-margin);
  font-size: var(--text-btn);
}

// Misc
article,
pre,
address,
blockquote {
  @apply my-5 py-5;
}

pre {
  @apply overflow-scroll;
}

ul.list {
  @apply mb-4;
  li {
    @apply list-disc list-inside mt-4;
  }
}

ol.list {
  @apply mb-4;
  li {
    @apply list-decimal list-inside mt-4;
  }
}

a {
  @apply text-primary;
  &:hover {
    @apply text-primary-light;
  }
}
