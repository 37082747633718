.leftMenu {
  li {
    a {
      @apply flex gap-2 flex items-center transition-all;
      span {
        @apply transition-all;
      }
      &:hover {
        span {
          @apply text-header;
        }
      }
    }
  }
  .active {
    .itemLabel {
      @apply text-header;
    }
  }
}
